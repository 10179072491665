.banner-slider {
  position: relative;
  z-index: 1;

  &__prev,
  &__next {
    display: flex;
    width: 10%;
    height: 100%;
    justify-content: center;
    align-items: center;
    font-size: 30px;
    color: $white;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    cursor: pointer;
    transition: .3s;
    background: linear-gradient(to right, rgba(#000, 0.5), transparent);
    opacity: 0;

    &:focus {
      outline: 0;
    }
  }

  &__next {
    background: linear-gradient(to left, rgba(#000, 0.5), transparent);
    left: auto;
    right: 0;
  }

  &:hover &__prev,
  &:hover &__next {
    opacity: 1;
  }
}

.project-slider {
  margin-bottom: 20px;

  &__frame {
    @include frame('rect', 80%);
  }

  @media (max-width: 991px) {
    margin-bottom: 10px;
  }
}

.video-slider,
.project-thumb-slider {
  position: relative;
  padding: 0 25px;

  &__frame {
    @include frame('rect', 80%);
  }

  &__prev,
  &__next {
    display: flex;
    width: 25px;
    height: 100%;
    justify-content: center;
    align-items: center;
    position: absolute;
    z-index: 10;
    top: 0;
    left: 0;
    font-size: 24px;
    color: $primary;
    cursor: pointer;

    &.swiper-button-disabled {
      display: none;
    }

    &:focus {
      outline: 0;
    }
  }

  &__next {
    left: auto;
    right: 0;
  }
}

.video-slider {
  &__item {
    position: relative;
  }

  &__overlay {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: rgba(#000, 0.15);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;

    &:hover {
      background-color: rgba(#000, 0.3);
    }

    img {
      max-height: 40%;
      object-fit: contain;
      object-position: 50% 50%;
    }
  }
}
