$navbar-breakpoint: 1200;

$brand-colors: (
  facebook: #37538d,
  twitter: #47a5ed,
  google-plus: #dd4b39,
  linkedin: #086ca5,
  pinterest: #bf1f24,
  youtube: #e73a35,
  skype: #00aff0,
  messenger: #1e88e5,
  zalo: #0080c8,
  vimeo: #1ab7ea
);

$facebook: #37538d;
$twitter: #47a5ed;
$google-plus: #dd4b39;
$linkedin: #086ca5;
$pinterest: #bf1f24;
$youtube: #e73a35;
$skype: #00aff0;
$messenger: #1e88e5;
$zalo: #0080c8;
$vimeo: #1ab7ea;

$gradient: linear-gradient(to right, #ff0000, #ffff00, #00ff00, #0000ff, #fb0094, #ff0000);
