.card-1 {
  display: flex;
  flex-direction: column;
  height: 100%;

  &__frame {
    @include frame('rect', 66.66%);
    @include frame('zoom');
  }

  &__title {
    font-size: 26px;
    font-weight: 400;
    margin: 30px 0 20px;
  }

  &__desc {
    @include max-line(3);
    margin-bottom: 20px;
  }

  &__link {
    font-size: 15px;
    font-weight: 700;
    text-transform: uppercase;
    color: $primary;

    &:hover {
      color: $primary;
    }
  }

  @media (max-width: 1199px) {
    &__title {
      font-size: 20px;
      margin: 16px 0 10px;
    }

    &__desc {
      margin-bottom: 16px;
    }
  }
}

.video {
  position: relative;

  &__frame {
    @include frame('rect', 56.25%);
  }

  &__overlay {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    z-index: 10;
    background-color: rgba(#000, 0.15);
    position: absolute;
    top: 0;
    left: 0;
    transition: .3s;

    &:hover {
      background-color: rgba(#000, 0.3);
    }

    img {
      max-height: 40%;
      object-fit: contain;
      object-position: 50% 50%;
    }
  }
}

.project {
  position: relative;
  overflow: hidden;

  &__frame {
    @include frame('rect', 56.25%);
  }

  &__overlay {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    background-color: rgba($primary, 0.7);
    color: $white;
    padding: 20px;
    text-align: center;
    font-size: 20px;
    font-weight: 700;
    transition: .5s;
    opacity: 0;
    transform: translateY(60%);

    &:hover {
      color: $white;
      text-decoration: none;
    }
  }

  &:hover &__overlay {
    transform: translateY(0);
    opacity: 1;
  }

  @media (max-width: 1199px) {
    &__overlay {
      font-size: 16px;
    }
  }
}

.card-2 {
  &__frame {
    @include frame('rect', 66.66%);
    @include frame('zoom');
  }

  &__title {
    font-size: 20px;
    font-weight: 700;
    text-transform: uppercase;
    text-align: center;
    margin: 16px 0 0;
  }

  @media (max-width: 1199px) {
    &__title {
      font-size: 16px;
      margin-top: 10px;
    }
  }
}

.card-3 {
  position: relative;

  &__frame {
    @include frame('rect', 80%);
    @include frame('zoom');
  }

  &__title {
    padding: 10px;
    background-color: rgba($primary, 0.7);
    font-size: 20px;
    color: $white;
    width: 100%;
    margin-bottom: 0;
    text-align: center;
    position: absolute;
    z-index: 10;
    bottom: 0;
    left: 0;

    a:hover {
      color: $white;
    }
  }

  @media (max-width: 1499px) {
    &__title {
      font-size: 16px;
      padding: 6px 8px;
    }
  }
}

.card-4 {
  display: flex;
  flex-direction: column;
  height: 100%;
  box-shadow: 0 3px 10px rgba(#000, 0.15);

  &__frame {
    @include frame('rect', 48%);
    @include frame('zoom');
  }

  &__title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 20px;
    font-weight: 700;
    text-transform: uppercase;
    text-align: center;
    color: $primary;
    margin-bottom: 0;
    padding: 12px;
  }

  @media (max-width: 767px) {

    &__title {
      font-size: 16px;
      padding: 10px;
    }
  }
}
