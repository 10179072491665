.header {
  position: relative;
  z-index: 100;

  &__inner {
    display: flex;
    align-items: center;
    padding: 10px 0;
    position: relative;
  }

  &__logo {
    display: inline-flex;

    img {
      height: 60px;
    }
  }

  &__elements {
    display: flex;
    align-items: center;
    margin-left: auto;
  }

  &__btn {
    margin-left: 20px;
  }

  @media (min-width: 1200px) {
    &__logo {
      margin-right: 20px;

      img {
        height: 70px;
      }
    }

    &__btn {
      display: none;
    }
  }

  @media (min-width: 1500px) {
    &__inner {
      padding: 15px 0;
    }

    &__logo {
      img {
        height: 80px;
      }
    }
  }
}

.lang {
  position: relative;

  &__text {
    padding: 4px 0;
    cursor: pointer;
  }

  &__dropdown {
    display: none;
    @include shadow('xs');
    min-width: 160px;
    padding: 4px 0;
    border-radius: 4px;
    z-index: 100;
    background-color: $white;
    position: absolute;
    top: 100%;
    right: 0;

    &::before,
    &::after {
      content: '';
      border: 6px solid transparent;
      border-bottom-color: $border-color;
      position: absolute;
      bottom: 100%;
      right: 20px;
    }

    &::after {
      margin-bottom: -1px;
      border-bottom-color: $white;
    }
  }

  &__item {
    display: flex;
    align-items: center;
    white-space: nowrap;
    padding: 6px 16px;
    color: $gray-800;

    &:hover {
      color: $gray-800;
      text-decoration: none;
      background-color: $light;
    }

    img {
      margin-right: 16px;
      height: 20px;
    }
  }

  &:hover &__dropdown {
    display: block;
  }
}
