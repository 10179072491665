.footer {
  background-color: $primary;
  padding: 50px 0 0;

  &__title {
    font-size: 20px;
    font-weight: 700;
    color: $white;
    margin-bottom: 20px;
  }

  &__grid {
    display: grid;
    grid-template-columns: 3fr 250px 250px 2fr;
    margin-bottom: 45px;
    grid-gap: 30px;
  }

  @media (max-width: 1499px) {
    &__grid {
      grid-template-columns: 3fr 170px 170px 2fr;
    }
  }

  @media (max-width: 1199px) {
    &__grid {
      grid-template-columns: 100%;
    }
  }
}

.f-contact {
  list-style: none;
  margin: 0;
  padding: 0;
  color: $white;

  &__item {
    position: relative;
    padding-left: 30px;

    &:not(:last-child) {
      margin-bottom: 8px;
    }

    .fa {
      position: absolute;
      top: 3px;
      left: 0;
    }
  }

  a,
  a:hover {
    color: $white;
  }
}

.f-menu {
  list-style: none;
  margin: 0;
  padding: 0;

  &__item {
    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }

  &__link {
    color: $white;

    &:hover {
      color: $white;
    }
  }
}

.endpage {
  background-color: #5B6770;
  padding: 16px 0;
  color: $white;

  &__inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.f-social {
  display: flex;

  &__item {
    display: flex;

    &:hover {
      text-decoration: none;
    }

    &:not(:last-child) {
      margin-right: 20px;
    }

    .fa {
      display: flex;
      width: 35px;
      height: 35px;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      color: $white;
      border: 1px solid $border-color;
      transition: .3s;
    }

    &:hover .fa-facebook {
      background-color: $facebook;
      border-color: $facebook;
    }

    &:hover .fa-twitter {
      background-color: $twitter;
      border-color: $twitter;
    }

    &:hover .fa-youtube {
      background-color: $youtube;
      border-color: $youtube;
    }
  }
}
