.checkbox-styled {
  display: inline-block;
  cursor: pointer;
  margin-bottom: 0;
  position: relative;
  padding-left: 30px;

  &__input {
    position: absolute;
    z-index: -1;
    opacity: 0;
  }

  &__icon {
    width: 18px;
    height: 18px;
    font-size: 18px;
    background-color: $white;
    border: 1px solid #cccccc;
    border-radius: 4px;
    position: absolute;
    top: 1px;
    left: 0;

    &::before {
      content: '\f00c';
      font-family: 'fontawesome';
      display: flex;
      width: 100%;
      height: 100%;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 0;
      left: 0;
      font-size: 13px;
      transform: scale(0);
    }
  }

  &:hover &__icon {
    border-color: $primary;
  }

  &__input:checked + &__icon {
    border-color: $primary;
    &::before {
      transform: scale(1);
      transition: transform 0.2s cubic-bezier(0.68, -0.75, 0.265, 1.75);
    }
  }

  &--empty {
    display: inline-flex;
    width: 21px;
    height: 21px;
    justify-content: center;
    align-items: center;
    padding: 0;
  }

  &--empty &__icon {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.radio-styled {
  display: inline-block;
  cursor: pointer;
  margin-bottom: 0;
  position: relative;
  padding-left: 30px;

  &__input {
    position: absolute;
    z-index: -1;
    opacity: 0;
  }

  &__icon {
    width: 18px;
    height: 18px;
    font-size: 18px;
    background-color: $white;
    border: 1px solid #cccccc;
    border-radius: 50%;
    position: absolute;
    top: 1px;
    left: 0;

    &::before {
      content: '';
      display: inline-block;
      width: 10px;
      height: 10px;
      align-items: center;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: scale(0) translate(-50%, -50%);
      background-color: $primary;
      border-radius: 50%;
    }
  }

  &:hover &__icon {
    border-color: $primary;
  }

  &__input:checked + &__icon {
    border-color: $primary;

    &::before {
      transform: scale(1) translate(-50%, -50%);
      transition: transform 0.2s cubic-bezier(0.68, -0.75, 0.265, 1.75);
    }
  }

  &--empty {
    display: inline-flex;
    width: 21px;
    height: 21px;
    justify-content: center;
    align-items: center;
    padding: 0;
  }

  &--empty &__icon {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.quantity {
  display: inline-flex;
  width: 100px;

  &__btn {
    width: 28px;
    border: 1px solid #ccc;
    cursor: pointer;
    position: relative;
    user-select: none;

    &::after {
      content: '\f068';
      display: flex;
      width: 100%;
      height: 100%;
      justify-content: center;
      align-items: center;
      font-family: 'fontawesome';
      font-size: 8px;
    }

    &:first-child {
      border-radius: 14px 0 0 14px;
      border-right: 0;
    }

    &:not(:first-child) {
      border-radius: 0 14px 14px 0;
      border-left: 0;

      &::after {
        content: '\f067';
      }
    }
  }

  &__input {
    flex: 1;
    height: 28px;
    min-width: 20px;
    max-width: 44px;
    border: 1px solid #ccc;
    border-left: 0;
    border-right: 0;
    text-align: center;
  }
}

.star-input {
  display: inline-flex;
  flex-direction: row-reverse;
  position: relative;
  top: 2px;
  z-index: 1;

  input {
    width: 20px;
    height: 20px;
    position: relative;
    z-index: 2;
    opacity: 0;
  }

  span {
    width: 0;
    height: 20px;
    position: relative;
    z-index: 1;

    &::before {
      content: '\f006';
      font-family: 'fontawesome';
      display: flex;
      width: 20px;
      height: 20px;
      justify-content: center;
      align-items: center;
      font-size: 20px;
      color: #666;
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  input:checked ~ span::before {
    content: '\f005';
    color: #ffc120;
  }

  &:hover span::before {
    content: '\f006' !important;
    color: #666 !important;
  }

  input:hover ~ span::before {
    content: '\f005' !important;
    color: #ffc120 !important;
  }
}

.stars {
  &::before {
    content: '\f005 \f005 \f005 \f005 \f005';
    font-family: 'fontawesome';
    letter-spacing: 4px;
    color: #ffc120 !important;
  }

  &--0::before {
    content: '\f006 \f006 \f006 \f006 \f006';
  }

  &--0-5::before {
    content: '\f123 \f006 \f006 \f006 \f006';
  }

  &--1::before {
    content: '\f005 \f006 \f006 \f006 \f006';
  }

  &--1-5::before {
    content: '\f005 \f123 \f006 \f006 \f006';
  }

  &--2::before {
    content: '\f005 \f005 \f006 \f006 \f006';
  }

  &--2-5::before {
    content: '\f005 \f005 \f123 \f006 \f006';
  }

  &--3::before {
    content: '\f005 \f005 \f005 \f006 \f006';
  }

  &--3-5::before {
    content: '\f005 \f005 \f005 \f123 \f006';
  }

  &--4::before {
    content: '\f005 \f005 \f005 \f005 \f006';
  }

  &--4-5::before {
    content: '\f005 \f005 \f005 \f005 \f123';
  }

  &--5::before {
    content: '\f005 \f005 \f005 \f005 \f005';
  }
}

.color-picker {
  display: flex;

  &__item {
    display: flex;
    position: relative;
    cursor: pointer;
    z-index: 1;
    margin-bottom: 0;

    &:not(:last-child) {
      margin-right: 10px;
    }

    input {
      position: absolute;
      z-index: -1;
      opacity: 0;
    }

    span {
      display: flex;
      width: 35px;
      height: 35px;
      border: 1px solid #fff;
      box-shadow: inset 0 0 0 2px #fff;
    }

    input:checked + span {
      border-color: #000;
    }
  }
}

.size-picker {
  &__item {
    position: relative;
    z-index: 1;
    cursor: pointer;
    margin-bottom: 0;

    &:not(:last-child) {
      margin-right: 16px;
    }

    input {
      position: absolute;
      opacity: 0;
      z-index: -1;
    }

    span {
      display: inline-block;
      font-size: 20px;
      text-transform: uppercase;
      padding: 4px;
      color: #999;
    }

    input:checked + span {
      color: #000;
    }
  }
}
