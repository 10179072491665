.section {
  padding: 45px 0 15px;

  &__title {
    font-size: 40px;
    font-weight: 400;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 20px;
  }

  &__desc {
    max-width: 1170px;
    margin: 0 auto 24px;
    text-align: center;
  }

  @media (max-width: 1199px) {
    &__title {
      font-size: 30px;
    }
  }

  @media (max-width: 575px) {
    &__title {
      font-size: 22px;
    }
  }
}

.section-2 {
  background-color: $light;
  padding: 35px 0 5px;

  &__body {
    display: flex;
    flex-direction: column;
    height: 100%;
    max-width: 600px;
  }

  &__title {
    font-size: 30px;
    font-weight: 400;
    margin-bottom: 8px;
    line-height: 1.4;
  }

  &__desc {
    font-size: 16px;
    margin-bottom: 30px;
  }

  &__btn {
    @include btn('fill');
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    padding: 0 24px;
    line-height: 46px;
    border-radius: 4px;
    border: 2px solid $primary;
    color: $primary;

    &:hover {
      color: $white;
      text-decoration: none;
    }

    &::before {
      background-color: $primary;
    }
  }

  @media (max-width: 1199px) {
    &__title {
      font-size: 24px;
    }

    &__btn {
      line-height: 38px;
      border-width: 1px;
    }
  }

  @media (max-width: 767px) {
    &__title {
      font-size: 20px;
    }
  }
}

.section-3 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;

  &__title {
    font-size: 24px;
    font-weight: 700;
    text-transform: uppercase;
    color: $primary;
    margin-bottom: 16px;
  }

  &__desc {
    font-size: 20px;
  }

  @media (max-width: 1499px) {
    &__title {
      font-size: 20px;
    }

    &__desc {
      font-size: 14px;
    }
  }
}
