.contact-page {
  &__title {
    font-size: 30px;
    font-weight: 700;
    margin-bottom: 28px;
  }

  @media (max-width: 991px) {
    &__title {
      margin-bottom: 10px;
    }
  }
}

.contact-form {
  &__btn {
    @include btn('filter');
    font-weight: 700;
    text-transform: uppercase;
    padding: 0 50px;
    line-height: 40px;
    border: 0;
    border-radius: 4px;
    background: $primary;
    color: $white;
  }

  .form-group {
    margin-bottom: 30px;
  }

  input.form-control {
    padding: 8px 20px;
    height: 40px;
  }

  textarea.form-control {
    padding: 8px 20px;
  }

  @media (max-width: 767px) {
    .form-group {
      margin-bottom: 16px;
    }
  }
}

.contact-info {
  &__title {
    font-size: 18px;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 24px;
  }

  &__item {
    align-items: center;

    & + & {
      margin-top: 30px;
    }

    a,
    a:hover {
      color: inherit;
    }
  }

  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    border: 1px solid rgb(206, 212, 218);
    margin-right: 18px;
    color: $primary;
    font-size: 24px;
  }

  &__label {
    font-size: 18px;
    font-weight: 700;
  }
}

.contact-map {
  height: 300px;
}
