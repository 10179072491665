.pagination {
  margin-bottom: 0;

  .page-item {
    &:not(:last-child) {
      margin-right: 4px;
    }
  }

  .page-link {
    color: $gray-800;
    padding: 8px 16px;
    border-radius: 4px;
  }
}

.breadcrumb {
  padding: 14px 0;

  &-item {
    &,
    & + &::before,
    &.active,
    &.active::before,
    a,
    a:hover {
      color: $white;
    }
  }
}

$phoneRingColor: #e73a35;
.phone-ring {
  display: flex;
  width: 40px;
  height: 40px;
  justify-content: center;
  align-items: center;
  color: #fff;
  border-radius: 50%;
  background-color: $phoneRingColor;
  position: fixed;
  z-index: 100;
  bottom: 50px;
  left: 50px;
  box-shadow: 0 5px 20px rgba(#000, 0.3);

  &:hover {
    color: $white;
  }

  &::before {
    content: "";
    display: block;
    width: 250%;
    height: 250%;
    border-radius: 50%;
    border: 1px solid $phoneRingColor;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    animation: phone-circle-animate 1s ease-in-out infinite;
  }

  &::after {
    content: "";
    display: block;
    width: 160%;
    height: 160%;
    border-radius: 50%;
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: -1;
    transform: translate(-50%, -50%);
    background-color: rgba($phoneRingColor, 0.5);
    animation: phone-circle-fill-animate 2s ease-in-out infinite;
  }

  &__icon {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    background-color: rgba($phoneRingColor, 0.7);
    border-radius: 50%;
    position: absolute;
    top: 0;
    left: 0;
    animation: phone-icon-animate 1s ease-in-out infinite;
  }

  &__text {
    display: inline-block;
    background-color: rgba($phoneRingColor, 0.7);
    font-size: 14px;
    font-weight: 700;
    line-height: 40px;
    padding: 0 20px 0 60px;
    color: $white;
    border-radius: 20px;
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    box-shadow: 0 5px 20px rgba(#000, 0.3);
  }

  &:hover &__text {
    color: $phoneRingColor;
    background-color: rgba(#fff, 0.7);
  }

  @media (max-width: 767px) {
    left: 20px;
    bottom: 20px;

    &__text {
      display: none;
    }
  }
}

.project-detail {
  &__title {
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 12px;
  }

  &__info {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    color: #999;
    margin-bottom: 16px;
  }

  &__body {
    max-width: 1110px;
    margin: 0 auto;
  }
}

.pd-aside {
  &__header {
    border-bottom: 1px solid $border-color;
    padding-bottom: 10px;
    padding-right: 24px;
    margin-bottom: 16px;
    position: relative;
  }

  &__title {
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    margin: 0;
  }

  &__item {
    &:not(:last-child) {
      margin-bottom: 16px;
    }

    .checkbox-styled {
      display: flex;
      align-items: baseline;
      padding-left: 0;
      padding-right: 24px;
      text-transform: uppercase;
    }

    .checkbox-styled__icon {
      left: auto;
      right: 0;
    }

    .checkbox-styled__text {
      color: #555;
      font-weight: 400;
      order: -1;
      flex: 1;
      margin-right: 6px;
    }

    .checkbox-styled__input:checked ~ .checkbox-styled__text {
      color: $primary;
    }
  }

  &__toggle {
    display: flex;
    width: 18px;
    height: 18px;
    justify-content: center;
    align-items: center;
    border: 1px solid #000;
    border-radius: 50%;
    position: absolute;
    top: 0;
    right: 1px;
    cursor: pointer;

    &::before,
    &::after {
      content: "";
      display: block;
      width: 8px;
      border-top: 1px solid #000;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) rotate(45deg);
    }

    &::after {
      transform: translate(-50%, -50%) rotate(-45deg);
    }
  }
}

.social-btns {
  display: flex;

  &__item {
    display: flex;

    &:not(:last-child) {
      margin-right: 10px;
    }

    img {
      height: 25px;
    }
  }
}

.support {
  &__thumb {
    width: 72px;
    height: 72px;
    border-radius: 50%;
    border: 1px solid #666;
    position: relative;
    margin-right: 24px;

    &::before,
    &::after {
      content: "";
      display: block;
      border: 6px solid transparent;
      border-left-color: #666;
      position: absolute;
      top: 50%;
      left: 100%;
      transform: translateY(-50%);
    }

    &::after {
      border-left-color: $white;
      margin-left: -1px;
    }
  }

  &__frame {
    @include frame("circle");
    border-radius: 50%;
  }

  &__content {
    margin-bottom: 4px;

    a,
    a:hover {
      color: inherit;
    }
  }
}

.pd-info-bar {
  color: #666;
  text-align: right;
  border-bottom: 1px solid $border-color;
  padding-bottom: 10px;
  margin-bottom: 16px;
}

.pd-detail {
  &__title {
    font-size: 30px;
    font-weight: 700;
    text-transform: uppercase;
  }

  &__info {
    border-top: 1px dotted $border-color;
    border-bottom: 1px dotted $border-color;
    padding: 10px 0;
    margin: 16px 0;
  }

  &__label {
    font-size: 18px;
    font-weight: 700;
    color: $primary;
    margin-bottom: 8px;
  }

  &__hotline {
    display: flex;
    align-items: center;
    font-size: 18px;
    font-weight: 700;
    color: $danger;

    img {
      margin-right: 16px;
    }

    a,
    a:hover {
      color: inherit;
    }
  }

  @media (max-width: 767px) {
    &__title {
      font-size: 24px;
    }
  }
}

.as-menu {
  list-style: none;
  margin: 0;
  padding: 0;

  &__item {
    &:not(:last-child) {
      margin-bottom: 4px;
    }
  }

  &__link {
    display: block;
    padding: 10px 30px;
    border-radius: 4px;
    color: $primary;
    background-color: #EEF7F2;
    transition: .3s;

    &:hover,
    &.active {
      background-color: $primary;
      color: $white;
      text-decoration: none;
    }
  }
}

.about {
  background-color: #eef7f2;
  padding: 20px 60px 40px;
  border-radius: 4px;
  position: relative;

  &__header {
    border-bottom: 2px solid $border-color;
    margin-bottom: 20px;
    padding: 20px 0;
    position: relative;
    z-index: 1;
  }

  &__title {
    font-size: 20px;
    font-weight: 700;
    text-transform: uppercase;
    text-align: center;
    color: $primary;
    margin-bottom: 0;
  }

  &__logo {
    height: 100%;
    padding: 8px;
    border-bottom: 2px solid $primary;
    position: absolute;
    left: 0;
    bottom: -2px;
    z-index: -1;

    img {
      height: 100%;
    }
  }

  &__border {
    position: absolute;
    top: -15px;
    left: -14px;

    &--2 {
      left: auto;
      top: -10px;
      right: -15px;
      transform: rotate(90deg);
    }

    &--3 {
      left: auto;
      top: auto;
      bottom: -14px;
      right: -14px;
      transform: rotate(180deg);
    }

    &--4 {
      top: auto;
      left: -17px;
      bottom: -11px;
      transform: rotate(-90deg);
    }
  }

  @media (max-width: 991px) {
    padding: 10px 30px 30px;

    &__header {
      padding: 16px 0;
    }
  }

  @media (max-width: 767px) {
    &__header {
      padding: 10px 0;
      margin-bottom: 10px;
    }

    &__title {
      font-size: 16px;
    }

    &__logo {
      display: none;
    }
  }
}

.post {
  &-title {
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 12px;
  }

  &-info {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    color: #666;
    margin-bottom: 16px;
  }
}

.tech {
  background-color: #F2F7F8;
  position: relative;
  overflow: hidden;

  &__img {
    width: 50%;
    height: 100%;
    object-fit: cover;
    object-position: 50% 50%;
    position: absolute;
    top: 0;
    right: 0;
  }

  &__body {
    padding: 40px 0 60px;
    padding-right: 60px;
  }

  &__title {
    font-size: 30px;
    font-weight: 400;
    text-transform: uppercase;
    color: $primary;
    margin-bottom: 24px;
  }

  &__desc {
    text-align: justify;
  }

  &__icon {
    display: flex;
    width: 60px;
    height: 60px;
    justify-content: center;
    align-items: center;
    padding: 12px;
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;

    &::before {
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      transform: rotate(45deg);
      background-color: $white;
      box-shadow: 0 1px 10px rgba(#000, 0.3);
    }

    img {
      max-width: 100%;
      max-height: 100%;
      object-fit: contain;
      object-position: 50% 50%;
    }
  }

  @media (max-width: 991px) {
    display: flex;
    flex-direction: column;
    padding-bottom: 0;

    &__body {
      padding: 40px 15px 60px !important;
    }

    &__img {
      display: block;
      width: 100%;
      position: static;
      order: 2;
    }
  }

  @media (min-width: 992px) {
    &__icon {
      width: 90px;
      height: 90px;
      top: 50%;
      left: 100%;
    }

    &--reverse &__icon {
      left: 0;
    }

    &--reverse &__img {
      right: auto;
      left: 0;
    }

    &--reverse &__body {
      margin-left: auto;
      padding-right: 0;
      padding-left: 60px;
    }
  }
}

.capacity {
  max-width: 1230px;
  margin: 0 auto;

  &__title {
    font-size: 30px;
    font-weight: 400;
    color: $primary;;
    margin-bottom: 12px;
  }

  &__body {
    margin-bottom: 30px;
  }
}
